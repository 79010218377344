var render = function () {
  var _vm$form$product;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-form', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.isLoading,
      expression: "isLoading"
    }],
    ref: "rftPackForm",
    staticClass: "mb-5",
    attrs: {
      "model": _vm.form,
      "label-width": "200px"
    }
  }, [(_vm$form$product = _vm.form.product) !== null && _vm$form$product !== void 0 && _vm$form$product.id && _vm.rftBid && (_vm.rftBid.specifications === undefined || !_vm.rftBid.specifications.length) && _vm.isSupplier ? _c('el-alert', {
    attrs: {
      "title": "Please enter at least one product specification on next tab",
      "closable": false,
      "type": "warning"
    }
  }) : _vm._e(), _c('el-form-item', {
    attrs: {
      "label": _vm.CombinationUtil.headermap_product.requested_product_name,
      "prop": "medicinal_product"
    }
  }, [_c('div', {
    staticClass: "pt-1",
    domProps: {
      "innerHTML": _vm._s(_vm.sourceProduct.requested_product_name)
    }
  })]), _c('el-form-item', {
    attrs: {
      "label": _vm.CombinationUtil.headermap_product.unit_quantity,
      "prop": "unit_quantity"
    }
  }, [_c('el-input', {
    attrs: {
      "value": _vm.sourceProduct.unit_quantity,
      "readonly": ""
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": _vm.CombinationUtil.headermap_bid.trade_product_name,
      "prop": "Medicine"
    }
  }, [_vm.form.product ? _c('el-autocomplete', {
    attrs: {
      "clearable": "",
      "fetch-suggestions": _vm.querySearchAsync,
      "placeholder": "Please type",
      "disabled": !_vm.editable
    },
    on: {
      "select": _vm.handleSelect,
      "clear": _vm.handleClear
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('div', {
          staticClass: "name"
        }, [_vm._v(_vm._s(item.name))]), _c('small', {
          staticClass: "uuid"
        }, [_vm._v(_vm._s(item.uuid))])];
      }
    }], null, false, 2883573449),
    model: {
      value: _vm.form.product.name,
      callback: function ($$v) {
        _vm.$set(_vm.form.product, "name", $$v);
      },
      expression: "form.product.name"
    }
  }, [_c('i', {
    staticClass: "el-icon-edit el-input__icon",
    attrs: {
      "slot": "suffix"
    },
    slot: "suffix"
  })]) : _vm._e()], 1), _c('el-form-item', [_vm._v(" By ticking the box the supplier confirms:"), _c('br'), _c('el-checkbox', {
    attrs: {
      "disabled": !_vm.editable
    },
    model: {
      value: _vm.form.adequate_supply,
      callback: function ($$v) {
        _vm.$set(_vm.form, "adequate_supply", $$v);
      },
      expression: "form.adequate_supply"
    }
  }, [_c('label', {
    staticClass: "pl-2",
    attrs: {
      "role": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.triggerCheckbox('adequate');
      }
    }
  }, [_vm._v("Adequate supply at the start of the contract date")])]), _c('br'), _c('el-checkbox', {
    attrs: {
      "disabled": !_vm.editable
    },
    model: {
      value: _vm.form.guarantee_minimum_shelf,
      callback: function ($$v) {
        _vm.$set(_vm.form, "guarantee_minimum_shelf", $$v);
      },
      expression: "form.guarantee_minimum_shelf"
    }
  }, [_c('label', {
    staticClass: "pl-2",
    attrs: {
      "role": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.triggerCheckbox('minshelf');
      }
    }
  }, [_vm._v("Guarantee minimum shelf life months as stated in the RFT")])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }